import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Strona główna | Canvas Whisper Tattoo Parlor
			</title>
			<meta name={"description"} content={"Tworzenie osobistych legend"} />
			<meta property={"og:title"} content={"Strona główna | Canvas Whisper Tattoo Parlor"} />
			<meta property={"og:description"} content={"Tworzenie osobistych legend"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65d461af0072400020ea53a5/images/4.jpg?v=2024-04-02T13:59:22.674Z"} />
			<link rel={"shortcut icon"} href={"https://quircosale.com/img/4287179.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quircosale.com/img/4287179.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quircosale.com/img/4287179.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quircosale.com/img/4287179.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quircosale.com/img/4287179.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quircosale.com/img/4287179.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://uploads.quarkly.io/65d461af0072400020ea53a5/images/4.jpg?v=2024-04-02T13:59:22.674Z) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="700px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h1" font="--headline2">
					Canvas Whisper Tattoo Parlor
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="120px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					Witamy w Canvas Whisper Tattoo Parlor, nowej przystani dla osobistej ekspresji poprzez tusz. W świecie, w którym każdy znak opowiada historię, jesteśmy tutaj, aby zapewnić, że Twoja historia zostanie usłyszana głośno i wyraźnie. Niezależnie od tego, czy jest to Twój pierwszy krok w świecie tatuaży, czy jesteś doświadczonym entuzjastą, który chce dodać do swojej kolekcji, nasze drzwi są otwarte. Oferujemy spokojną, zachęcającą atmosferę, w której komfort i wizja są naszymi priorytetami.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-15">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 0px 16px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				justify-content="space-between"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
						md-text-align="left"
					>
						O nas
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						Canvas Whisper wyróżnia się jako latarnia kreatywności i osobistego opowiadania historii w społeczności tatuatorów. Nasze studio to tygiel artystycznej ekspresji, w którym każdy tatuaż jest arcydziełem samym w sobie. Nasi oddani artyści z pasją ożywiają Twoje wizje, specjalizując się w różnych stylach, aby dopasować się do Twojej unikalnej narracji. Od momentu wejścia do Canvas Whisper stajesz się częścią podróży transformacji i ekspresji, dostosowanej specjalnie dla Ciebie.
					</Text>
				</Box>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box width="100%">
					<Image src="https://uploads.quarkly.io/65d461af0072400020ea53a5/images/5.jpg?v=2024-04-02T13:59:22.668Z" width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-7">
			<Text margin="0px 0px 50px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
				Dlaczego warto wybrać Canvas Whisper?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="0 40px"
				md-grid-template-columns="1fr"
				md-grid-gap="50px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
								Doświadczenie i kunszt
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Nasz zespół składa się z wysoko wykwalifikowanych artystów, z których każdy specjalizuje się w różnych stylach tatuażu. Od zawiłych linii po odważne kolory, realizujemy Twoje pomysły z precyzją i pasją.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
								Spersonalizowane doświadczenie
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Rozumiemy, że każdy tatuaż to osobista podróż. Dlatego poświęcamy czas na zrozumienie Twojej wizji i preferencji, zapewniając, że końcowy element jest tak wyjątkowy, jak Twoja historia.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
								Najwyższe standardy bezpieczeństwa
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Twoje zdrowie i bezpieczeństwo są najważniejsze. Canvas Whisper przestrzega ścisłych protokołów higieny, wykorzystując najnowsze techniki sterylizacji i wysokiej jakości, bezpieczny atrament.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
								Komfort i opieka
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Wierzymy, że doświadczenie związane z wykonywaniem tatuażu powinno być równie niezapomniane, jak sam tatuaż. Nasze studio zostało zaprojektowane z myślą o Twoim komforcie, zapewniając relaksujące, pozytywne wrażenia.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});